import './App.css';
import 'ol/ol.css';
import {useEffect, useRef} from "react";
import VectorTileLayer from "ol/layer/VectorTile";
import {applyStyle, stylefunction} from "ol-mapbox-style";
import MapOL from 'ol/Map';
import {Collection, Feature, View} from "ol";
import {Control, defaults, MousePosition} from "ol/control";
import {Fill, RegularShape, Style} from "ol/style";
import VectorSource from "ol/source/Vector";
import {GeoJSON} from "ol/format";
import VectorLayer from "ol/layer/Vector";
import TileLayer from "ol/layer/Tile";
import {OSM} from "ol/source";
import {ApolloClient, gql, InMemoryCache} from "@apollo/client";
import {register} from 'ol/proj/proj4';
import {get as getProjection} from 'ol/proj';
import proj4 from 'proj4';
import {Point} from "ol/geom";

const colors = {
    '(1:3)': 'rgb(241,84,105)',
    '(1:4)': 'rgb(104,228,86)',
    '(1:1)': 'rgb(244,218,72)',
    '(1:6)': 'rgb(244,218,72)',
    '(1:8)': 'rgb(165,42,42)',
}

const renderer = (f, state) => {
    if (state.resolution > 600)
        return;
    const [x, y] = f;
    const feature = state.feature;
    const color = colors[feature.get('COLOUR')];
    const height = feature.get('HEIGHT');
    const litvis = feature.get('LITVIS');
    const catlit = feature.get('CATLIT');
    const sectr1 = feature.get('SECTR1');
    const sectr2 = feature.get('SECTR2');
    const valnmr = feature.get('VALNMR');
    const radius = 150;
    const lightRadius = 40;
    const ctx = state.context;
    if (!color || valnmr === undefined || valnmr < 7) return;
    if (feature.get('SECTR1')) {
        const ang1 = sectr1 / 180.0 * Math.PI;
        const ang2 = sectr2 / 180.0 * Math.PI;
        ctx.restore();
        ctx.save();
        ctx.beginPath();
        ctx.setLineDash([5, 5]);
        ctx.moveTo(x, y);
        ctx.lineTo(x - radius * Math.sin(ang1), y + radius * Math.cos(ang1));
        ctx.moveTo(x, y);
        ctx.lineTo(x - radius * Math.sin(ang2), y + radius * Math.cos(ang2));
        ctx.stroke();
        ctx.closePath();
        ctx.restore();
        ctx.save();
        ctx.beginPath();
        ctx.arc(x, y, lightRadius, Math.PI/2 + ang1, Math.PI/2 + ang2, false);
        ctx.lineWidth = 3.5;
        ctx.strokeStyle = 'black';
        ctx.stroke();
        ctx.lineWidth = 3;
        ctx.strokeStyle = color;
        ctx.stroke();
        ctx.closePath();
        ctx.restore();
        ctx.save();
    } else {
        ctx.save();
        ctx.beginPath();
        ctx.arc(x, y, lightRadius, 0, 2 * Math.PI, false);
        ctx.lineWidth = 3.5;
        ctx.strokeStyle = 'black';
        ctx.stroke();
        ctx.lineWidth = 3;
        ctx.strokeStyle = color;
        ctx.stroke();
        ctx.closePath();
        ctx.restore();
    }
}


class ButtonControl extends Control {
    constructor(opt_options) {
        const options = opt_options || {};

        const button = document.createElement('button');
        button.innerHTML = 'N';

        const element = document.createElement('div');
        element.className = 'rotate-north ol-unselectable ol-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target,
        });

        button.addEventListener('click', this.handleRotateNorth.bind(this), false);
    }

    handleRotateNorth() {
        const ls = this.getMap().getLayers();
        const layer = ls.item(2);
        const source = layer.getSource();
        const extent = this.getMap().getView().calculateExtent(this.getMap().getSize());
        const f = source.getFeaturesInExtent(extent);
        f.forEach((x) => {
            x.setGeometry(new Point([6806485, 11126519]));
        });
        source.changed();
    }
}

function App() {
    proj4.defs(
        'EPSG:3413',
        '+proj=stere +lat_0=90 +lat_ts=70 +lon_0=-45 +k=1 ' +
        '+x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'
    );
    register(proj4);
    const proj3413 = getProjection('EPSG:3413');
    proj3413.setExtent([-4194304, -4194304, 4194304, 4194304]);
    const mapRef = useRef();
    const map = useRef();

    useEffect(() => {
        // const osm = new VectorTileLayer({renderMode: 'vector', declutter: true});
        //applyStyle(osm, 'https://osm.ivazh.ru/styles/basic/style.json');
        // applyStyle(osm, 'https://s57.ivazh.ru/styles/vn1/style.json');

        map.current = new MapOL({
            controls: defaults().extend([new ButtonControl()]),
            target: mapRef.current,
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
                //osm
            ],
            view: new View({
                //projection: 'EPSG:3413',
                zoom: 10,
                center: [0, 0]
                //center: [6806485, 11126519]
                //center: [3261991.623157039750367, -2229040.320433085784316]
            }),
        });

        map.current.addControl(new MousePosition());

        // const client = new ApolloClient({
        //     cache: new InMemoryCache(),
        //     uri: 'https://sno.ivazh.ru/graphql'
        // });
        // client.query({
        //     query: gql`
        //     query load($code: String!)
        //     {
        //         loadAllFeatures(code: $code) {
        //           features
        //         }
        //     }`,
        //     variables: {
        //         code: 'code_18'
        //     }
        // }).then((res) => {
        //const geojson = res.data.loadAllFeatures.features;
        // const fs = new GeoJSON().readFeatures(geojson, {
        //     dataProjection: 'EPSG:4326',
        //     featureProjection: 'EPSG:3413'
        // });
        // const lightsLayer = new VectorLayer({
        //     source: new VectorSource({
        //         //features: fs
        //         format: new GeoJSON(),
        //         url: 'LIGHTS.geojson',
        //     }),
        //     style: (feature, res) => {
        //         return new Style({
        //             zIndex: 100,
        //             renderer: renderer,
        //             hitDetectionRenderer: renderer
        //         })
        //     }
        // });
        // map.current.addLayer(lightsLayer);

        // });

        // const layerMap = {
        //     'BOYLAT': 'code_002',
        //     'BOYSAW': 'code_005',
        //     'BOYSPP': 'code_006',
        //     'LIGHTS': 'code_18',
        //     'WRECKS': 'code_20',
        //     'SOUNDG': 'code_19'
        // }
        // const layerArray = ['BOYLAT', 'BOYSAW', 'BOYSPP', 'LIGHTS', 'WRECKS', 'SOUNDG'];
        // layerArray.forEach((layerName) => {
        //     const client = new ApolloClient({
        //         cache: new InMemoryCache(),
        //         uri: 'https://sno.ivazh.ru/graphql'
        //     });
        //     client.query({
        //         query: gql`
        //     query load($code: String!)
        //     {
        //         loadAllFeatures(code: $code) {
        //           features
        //         }
        //     }`,
        //         variables: {
        //             code: layerMap[layerName]
        //         }
        //     }).then((res) => {
        //         const geojson = res.data.loadAllFeatures.features;
        //         const layer = new VectorLayer({declutter: false});
        //
        //         fetch('https://s57.ivazh.ru/styles/s57/style.json').then(async (x) => {
        //             const glStyle = await x.json();
        //             glStyle.layers = glStyle.layers.filter((x) => x.id.startsWith(layerName));
        //             glStyle.layers.forEach((x) => {
        //                 delete x['source-layer'];
        //             });
        //             glStyle.sources.s57 = {
        //                 data: geojson,
        //                 type: 'geojson',
        //             }
        //             applyStyle(layer, glStyle);
        //         });
        //
        //         map.current.addLayer(layer);
        //     });
        // });

        const layer = new VectorLayer({declutter: false});

        fetch('https://s57.ivazh.ru/styles/r14/style.json').then(async (x) => {
            const glStyle = await x.json();
            // glStyle.layers = glStyle.layers.filter((x) => x.id.startsWith('WRECKS'));
            // glStyle.layers.forEach((x) => {
            //     delete x['source-layer'];
            // });
            glStyle.sources.s57 = {
                data: 'data.geojson',
                type: 'geojson',
            }
            applyStyle(layer, glStyle);
        });

        map.current.addLayer(layer);

        //
        // map.current.on('click', (e) => {
        //     const feature = map.current.forEachFeatureAtPixel(e.pixel, (feature, l) => {
        //         if (l === layer)
        //             return feature;
        //     });
        //     if (feature) {
        //         console.log(feature);
        //     }
        // });
    }, []);

    return (
        <div ref={mapRef} style={{width: '100vw', height: '100vh'}}>
        </div>
    );
}

export default App;
